import Swal from "sweetalert2";

export default function AlertaCentral(mensaje: string, reload?: string, footer?: string, text?: string, tk?: string) {

    let Title: any = "";
    let Html = "";
    let Text: any = "";
    let Icon: any = "";
    let ConfirmeButtonText: any = "";
    let Footer: any = "";
    let AllowOutsideClick:any = true;
    let ConfirmButtonColor = "";
    let Ticket:any = "";

    if (mensaje == String("ERRORCARGATICKET")) {

      Title = 'Error!';
      Html = 'Problema al recuperar <b>Tickets</b>';
      Text = "Búsqueda Erronea";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";



      // Title = "No ha Ingresado Orden de Flete";
      // Text = "Debe Ingresar una Orden de Flete";
      // Icon = "error";
      // ConfirmeButtonText = "Continuar";
      // Footer = "";

    } else if (mensaje == String("NOBULTOCIEGO")) {

      Title = 'Alerta!';
      Html = 'Sucursal Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";


      // Title = "Error";
      // Text = "Problema con la Orden de Flete";
      // Icon = "error";
      // ConfirmeButtonText = "Continuar";
      // Footer = footer;

    } else if (mensaje == String("OFCORRECTA")) {

      Title = 'Correcto!';
      Html = '<p><h3>Se ha creado un ticket.</h3></p> <p> "Anular y reemplazar bulto ciego"</p><p> con el numero:</p><p><h2><b>s-2301-123</b></h2></p><p> <em>(Asignado a quien creó el ticker de bulto ciego)</em></p>';
      Text = "Búsqueda Correcta";
      Icon = "success";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "green";

    } else if (mensaje == String("NOBULTOCIEGOCOLOR")) {

      Title = 'Alerta!';
      Html = 'El color Seleccionado no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    } else if (mensaje == String("DIASMAXIMOS")) {

      Title = "Error";
      Text = "El rango máximo de días es de 15, límite superado. ";
      Icon = "error";
      ConfirmeButtonText = "Cerrar";
      Footer = "";

    } else if (mensaje == String("NOTICKETRANGOFECHA")) {

      Title = 'Alerta!';
      Html = 'Rango de Fechas Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";



    } else if (mensaje == String("NOBULTOCIEGOMULTI")) {

      Title = 'Alerta!';
      Html = 'La Busqueda avanzada Seleccionada no registra Bultos Ciegos';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("RANGOFECHASUPERIOR")) {

      Title = 'Alerta!';
      Html = 'Las Fechas Seleccionadas Son mas Antiguas que 7 meses.';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFNOEXISTE")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada no Existe </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFBULTOCIEGO")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada es la misma del Ticket  </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("OFNOALTERABLE")) {

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada posee Estado no Alterable </br>' + text;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }

    else if (mensaje == String("OFCONTICKET")) {

      Ticket = tk;

      Title = 'Error!';
      Html = 'La Orden de Flete Ingresada <b>'+ text +'</b> <br> \n posee Tickets en TOPdesk </br> <h3> <b>' + Ticket + '</b> </h3>' ;     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }

    else if (mensaje == String("DEBEINGRESAROF")) {

      Title = 'Error!';
      Html = 'DEBE INGRESAR UNA ORDEN DE FLETE';     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else if (mensaje == String("TICKETOFMALA")) {

      Title = 'Error!';
      Html = 'Ticket Sin Orden de FLete \n No se puede gestionar';     
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }  else if (mensaje == String("DIASMAXIMOS2")) {

      Title = "Error";
      Html = 'El rango <b>Máximo</b> de días es de <b>15</b>. <br> Has superado el límite. <br> Los resultados no incluirán el filtro de Fecha.'
      Text = "El rango máximo de días es de 15, límite superado. ";
      Icon = "error";
      ConfirmeButtonText = "Cerrar";
      Footer = "";

    }
    else if (mensaje == String("RANGOFECHASUPERIOR2")) {

      Title = 'Alerta!';
      Html = 'Las Fechas Seleccionadas son más Antiguas que <b> 7 meses </b>. <br> Los resultados no incluirán el filtro de Fecha.';
      Text = "Búsqueda Sin Resultados";
      Icon = "error";
      ConfirmeButtonText = "Continuar";
      AllowOutsideClick = false;
      Footer = "";
      ConfirmButtonColor = "red";

    }
    else {

      Title = "Error Indeterminado";
      Text = "Algo a fallado";
      Icon = "success";
      ConfirmeButtonText = "Continuar";
      Footer = "";

    }








    if (reload !== "recargar") {

      Swal.fire({
        title: Title,
        html: Html,
        text: Text,
        icon: Icon,
        confirmButtonText: ConfirmeButtonText,
        allowOutsideClick: AllowOutsideClick,
        footer: Footer,
        confirmButtonColor: ConfirmButtonColor,
        
      });

    } else {

      Swal.fire({
        title: Title,
        html: Html,
        text: Text,
        icon: Icon,
        confirmButtonText: ConfirmeButtonText,
        allowOutsideClick: AllowOutsideClick,
        footer: Footer,
        confirmButtonColor: ConfirmButtonColor,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

    }


  }

