
import React, { useEffect, useState } from "react";

import axios from "axios";

import Rezago from '../../API/MICROSERVICES/rezago/rezago';
import Tracking from "../../API/MICROSERVICES/tracking/tracking";
import Topdesk from "../../API/MICROSERVICES/topdesk/topdesk";
import { TDK_DIAS_ANTERIORES } from "../../API/CONSTANTS/constants";
import moment from "moment";



export default function Pruebas(props: any) {


  useEffect(() => {
    algo();

  }, []);


  async function algo() {

    let efecha =  "2023-06-11T";
    let id = "9533b9b8-3083-41c1-ad7d-887fd56edefd";

 
    let tipo = await Topdesk.BusquedaTipoTicketFiltrada(id, efecha).then((resp)=>{
        console.log(resp);
    }); 

    console.log(tipo);






  }


  async function CalcularFechaAnterior() {

    let diasAntes = TDK_DIAS_ANTERIORES;

    let fechaAnterior = moment().add('days', -diasAntes).format('YYYY-MM-DDT');

    return fechaAnterior;

  }



  return (
    <>
      <h1>Hola Mundo</h1>
      <h1>Chao Mundo</h1>

    </>
  )

}

